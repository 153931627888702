import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue-next/dist/bootstrap-vue-next.css";
import ModalPlugin from 'bootstrap-vue-next';
import BootstrapVueNext from 'bootstrap-vue-next';
import { EventType } from "@azure/msal-browser";
import { msalInstance } from "./authConfig";
import { msalPlugin } from "./plugins/msalPlugin";
import { CustomNavigationClient } from "./router/NavigationClient";
import Notifications from '@kyvg/vue3-notification';
import Vue3Lottie from 'vue3-lottie';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope, faUser } from "@fortawesome/free-regular-svg-icons";
import { faCog, faCheck, faBan, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
const navigationClient = new CustomNavigationClient(router);
msalInstance.setNavigationClient(navigationClient);
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}
msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
    }
});
const app = createApp(App);
library.add(faEnvelope, faCog, faCheck, faBan, faSpinner, faUser);
app.component("font-awesome-icon", FontAwesomeIcon);
app.use(store);
app.use(router);
app.use(Notifications);
app.use(Vue3Lottie);
app.use(msalPlugin, msalInstance);
app.use(ModalPlugin);
app.use(BootstrapVueNext);
router.isReady().then(() => app.mount('#app'));
