class TopicTreeUpdaterService {
    update(root, payload) {
        root?.forEach(topic => {
            this.recurse(topic, payload);
        });
    }
    recurse(innerTopic, payload) {
        if (payload.topicId === innerTopic.id) {
            innerTopic.children = payload.navigation;
        }
        else if (innerTopic.hasChildren && innerTopic.children) {
            innerTopic.children.forEach(topic => {
                this.recurse(topic, payload);
            });
        }
    }
}
export const topicTreeUpdaterService = new TopicTreeUpdaterService();
