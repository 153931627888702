export const PRESSURECONST = {
    BAR: { value: "BAR", text: "bar(e)" },
    PSI: { value: "PSI", text: "psig" },
};
export const POWERCONST = {
    KW: { value: "KW", text: "kW" },
    hp: { value: "HP", text: "hp" },
};
export const FLOWCONST = {
    LPS: { value: "LPS", text: "l/s" },
    CFM: { value: "CFM", text: "cfm" },
};
export const TEMPERATURECONST = {
    C: { value: "C", text: "°C" },
    F: { value: "F", text: "°F" },
};
export const FORCECONST = {
    N: { value: "N", text: "N" },
    LBF: { value: "LBF", text: "lbf" },
};
export const LENGTHCONST = {
    MM: { value: "MM", text: "mm" },
    IN: { value: "IN", text: "in" },
};
export const VOLUMECONST = {
    L: { value: "L", text: "Litre" },
    USGAL: { value: "USGAL", text: "US Gallon" },
    IMPGAL: { value: "IMPGAL", text: "Imp Gallon" },
    CUFT: { value: "CUFT", text: "Cubic Feet" },
};
export const MASSCONST = {
    KG: { value: "KG", text: "kg" },
    LB: { value: "LB", text: "lb" },
};
