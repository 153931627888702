export const MUTATION_TYPES = {
    SET_NAVIGATION: "setNavigation",
    SET_BANNER: "setBanner",
    SET_CONTENT: "setContent",
    SET_METADATA: "setMetadata",
    SET_USER: "setUser",
    SET_ACCESS_TOKEN: "setAccessToken",
    SET_LOADING_NAV: "setLoadingNav",
    SET_LOADING_CONT: "setLoadingCont",
    SET_UOM: "setUOM",
    SET_VIEWER_GUID: "setViewerGuid",
    SET_LAST_URL: "setLastUrl",
    SET_LOADING_LANG: "setLoadingLang"
};
