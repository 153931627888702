import { apiHelper, SuccessActionResult, FailActionResult, } from "@/common/helpers/ApiHelper";
import axios from "axios";
import store from "@/store";
class DdpViewerService {
    getAuthorizationConfig(params) {
        //@ts-ignore
        if (store.state.User.accessToken && store.state.User.accessToken != "") {
            return {
                //@ts-ignore
                headers: { Authorization: `Bearer ${store.state.User.accessToken}` },
                params,
            };
        }
        return {};
    }
    getAuthorizatedRequest(params) {
        return {
            //@ts-ignore
            headers: { Authorization: `Bearer ${store.state.User.accessToken}` },
            params: params,
        };
    }
    async getCurrentUser() {
        try {
            const url = apiHelper.createUrl("user");
            const response = await axios.get(`${url}`, this.getAuthorizationConfig());
            return new SuccessActionResult(response.data);
        }
        catch (error) {
            if (axios.isAxiosError(error)) {
                const errorModel = error?.response?.data;
                return new FailActionResult(errorModel);
            }
            return new FailActionResult({ errors: ["Unexpected error occurred."] });
        }
    }
    async getContent(viewerGuid, contentUrl) {
        const url = apiHelper.createUrl("viewer/content");
        try {
            //const response = await axios.get(url, this.getAuthorizatedRequest({id: viewerGuid, url: contentUrl}));
            const response = await axios.post(url, {
                unitsOfMeasurement: store.state.uom,
                id: viewerGuid,
                url: contentUrl,
            }, this.getAuthorizationConfig());
            return new SuccessActionResult(response.data);
        }
        catch (error) {
            if (axios.isAxiosError(error)) {
                const errorModel = error?.response?.data;
                return new FailActionResult(errorModel);
            }
            return new FailActionResult({ errors: ["Unexpected error occurred."] });
        }
    }
    async getBannerUrl() {
        const url = apiHelper.createUrl("viewer/banner");
        try {
            const response = await axios.get(url, this.getAuthorizationConfig());
            return new SuccessActionResult(response.data);
        }
        catch (error) {
            if (axios.isAxiosError(error)) {
                const errorModel = error?.response?.data;
                return new FailActionResult(errorModel);
            }
            return new FailActionResult({ errors: ["Unexpected error occurred."] });
        }
    }
    async getNavigation(viewerGuid, topic) {
        const url = apiHelper.createUrl("viewer/navigation");
        try {
            const response = await axios.get(url, this.getAuthorizatedRequest({ id: viewerGuid, topic }));
            return new SuccessActionResult(response.data);
        }
        catch (error) {
            if (axios.isAxiosError(error)) {
                const errorModel = error?.response?.data;
                return new FailActionResult(errorModel);
            }
            return new FailActionResult({ errors: ["Unexpected error occurred."] });
        }
    }
    async getMetadata(viewerGuid) {
        const url = apiHelper.createUrl("viewer/metadata");
        try {
            const response = await axios.get(url, this.getAuthorizatedRequest({ id: viewerGuid }));
            return new SuccessActionResult(response.data);
        }
        catch (error) {
            if (axios.isAxiosError(error)) {
                const errorModel = error?.response?.data;
                return new FailActionResult(errorModel);
            }
            return new FailActionResult({ errors: ["Unexpected error occurred."] });
        }
    }
    async postMailRequest(email, guid) {
        const url = apiHelper.createUrl("viewer/sendpdfwithmail");
        try {
            const response = await axios.post(url, {
                email,
                token: guid,
                unitsOfMeasurement: store.state.uom,
            }, this.getAuthorizationConfig());
            return new SuccessActionResult(response.data);
        }
        catch (error) {
            if (axios.isAxiosError(error)) {
                const errorModel = error?.response?.data;
                return new FailActionResult(errorModel);
            }
            return new FailActionResult({ errors: ["Unexpected error occurred."] });
        }
    }
    async changeLanguage(viewerGuid, language) {
        const url = apiHelper.createUrl("viewer/changelanguage");
        try {
            const response = await axios.post(url, {
                id: viewerGuid,
                language,
            }, this.getAuthorizationConfig());
            return new SuccessActionResult(response.data);
        }
        catch (error) {
            if (axios.isAxiosError(error)) {
                const errorModel = error?.response?.data;
                return new FailActionResult(errorModel);
            }
            return new FailActionResult({ errors: ["Unexpected error occurred."] });
        }
    }
}
export const ddpViewerService = new DdpViewerService();
