import { createRouter, createWebHistory } from 'vue-router';
import { ROUTENAMES } from "./route-names";
import { registerGuard } from "./routerGuard";
const routes = [
    {
        path: "/:guid?",
        name: ROUTENAMES.HOME,
        component: () => import("@/views/Home.vue"),
    },
];
const router = createRouter({
    history: createWebHistory("#"),
    linkExactActiveClass: "active",
    routes
});
registerGuard(router);
export default router;
