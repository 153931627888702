import { UserVm, RoleVm } from "@/models/user";
import { MUTATION_TYPES } from "@/store/types/mutation-types";
class State {
    user = null;
    accessToken = null;
}
const User = {
    namespaced: true,
    state: new State(),
    mutations: {
        [MUTATION_TYPES.SET_USER](state, data) {
            const roles = data.idTokenClaims?.roles?.map((x) => new RoleVm(x));
            const user = new UserVm({ id: data.homeAccountId, roles, userName: data.username });
            state.user = Object.assign({}, user);
        },
        [MUTATION_TYPES.SET_ACCESS_TOKEN](state, data) {
            state.accessToken = data;
        }
    }
};
export default User;
