export const ACTION_TYPES = {
    GET_NAVIGATION: "getNavigation",
    GET_BANNER: "getBanner",
    GET_CONTENT: "getContent",
    GET_METADATA: "getMetadata",
    POST_MAILREQUEST: "postMailRequest",
    SET_LOADING_NAV: "setLoadingNav",
    SET_LOADING_CONT: "setLoadingCont",
    CHANGE_LANGUAGE: "changeLanguage",
    SET_VIEWER_GUID: "setViewerGuid"
};
export const GETTER_TYPES = {
    HAS_ACCESS: "hasAccess",
};
