import { isNullOrUndefined } from "../Utils";
class ApiHelper {
    static API_BASE_URL = process.env.VUE_APP_DDP_VIEWER_API_URL;
    static BASE_PATH = "/api";
    createUrl(path, ...params) {
        let url = `${ApiHelper.API_BASE_URL}${ApiHelper.BASE_PATH}/${path}`;
        for (let i = 0; i < params.length; i++) {
            const param = params[i];
            if (param !== null) {
                url += `/${param}`;
            }
        }
        return url;
    }
}
export const apiHelper = new ApiHelper();
export class ActionResult {
    _isSuccess;
    _result;
    _errorModel;
    constructor(isSuccess, result, errorModel) {
        this._isSuccess = isSuccess;
        this._result = isNullOrUndefined(result) ? null : result;
        this._errorModel = isNullOrUndefined(errorModel)
            ? null
            : errorModel;
    }
    get isSuccess() {
        return this._isSuccess;
    }
    get result() {
        return this._result;
    }
    get errorModel() {
        return this._errorModel;
    }
}
export class SuccessActionResult extends ActionResult {
    constructor(result) {
        super(true, result);
    }
}
export class FailActionResult extends ActionResult {
    constructor(errorModel) {
        super(false, undefined, errorModel);
    }
}
