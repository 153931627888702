/* eslint-disable */
export class UserVm {
    id;
    roles;
    userName;
    constructor({ id, roles, userName }) {
        this.id = id;
        this.roles = roles;
        this.userName = userName;
    }
}
export class RoleVm {
    key;
    constructor(key) {
        this.key = key;
    }
}
