import { LogLevel, PublicClientApplication } from "@azure/msal-browser";
/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
    auth: {
        clientId: process.env.VUE_APP_APPSECURITY_CLIENTID ? process.env.VUE_APP_APPSECURITY_CLIENTID : "",
        authority: process.env.VUE_APP_APPSECURITY_AUTHORITY ? process.env.VUE_APP_APPSECURITY_AUTHORITY : "",
        redirectUri: "/",
        // redirectUri: process.env.VUE_APP_LOGIN_REDIRECT_URL?process.env.VUE_APP_LOGIN_REDIRECT_URL:"",
        postLogoutRedirectUri: '/',
        navigateToLoginRequestUrl: true,
        clientCapabilities: ['CP1'], // this lets the resource owner know that this client is capable of handling claims challenge.
    },
    cache: {
        cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        allowNativeBroker: false,
        loggerOptions: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                    case LogLevel.Verbose:
                        console.info(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
            logLevel: LogLevel.Error
        },
    },
};
export const msalInstance = new PublicClientApplication(msalConfig);
export const loginRequest = {
    scopes: ["User.Read"],
};
export const apiScope = `api://${process.env.VUE_APP_APPSECURITY_CLIENTID ? process.env.VUE_APP_APPSECURITY_CLIENTID : ""}/API.Request`;
export const apiTokenRequest = {
    scopes: [
        apiScope,
    ],
};
