import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { apiTokenRequest, loginRequest } from "../authConfig";
export default function getTokenFromSessionStorage() {
    const appKeys = sessionStorage.getItem(`msal.token.keys.${process.env.VUE_APP_APPSECURITY_CLIENTID ? process.env.VUE_APP_APPSECURITY_CLIENTID : ""}`);
    const activeAccount = sessionStorage.getItem(`msal.${process.env.VUE_APP_APPSECURITY_CLIENTID ? process.env.VUE_APP_APPSECURITY_CLIENTID : ""}.active-account`);
    if (appKeys && activeAccount) {
        const msalTokenKeys = JSON.parse(appKeys);
        const tokenObject = JSON.parse(sessionStorage.getItem(`${msalTokenKeys.idToken[0]}`) ?? "");
        if (tokenObject && tokenObject?.secret) {
            return tokenObject.secret;
        }
    }
    return null;
}
export async function acquireAuth(instance, inProgress) {
    await instance.initialize();
    const accessTokenRequest = {
        ...apiTokenRequest,
        account: instance.getAllAccounts()[0],
    };
    if (inProgress === InteractionStatus.Startup) {
        if (accessTokenRequest.account) {
            await instance
                .acquireTokenSilent({ account: accessTokenRequest.account })
                .catch((error) => {
                // Acquire token interactive failure
                if (error instanceof InteractionRequiredAuthError) {
                    throw (error);
                }
            });
        }
    }
    else if (inProgress === InteractionStatus.Login) {
        if (accessTokenRequest.account) {
            await instance
                .acquireTokenSilent({ account: accessTokenRequest.account })
                .catch((error) => {
                // Acquire token interactive failure
                if (error instanceof InteractionRequiredAuthError) {
                    async () => await instance
                        .acquireTokenPopup(loginRequest)
                        .catch(function (error) {
                        throw (error);
                    });
                    throw error;
                }
            });
        }
        else {
            await instance
                .acquireTokenPopup(loginRequest)
                .catch(function (error) {
                throw error;
            });
        }
        if (instance.getAllAccounts() && instance.getAllAccounts().length > 0) {
            instance.setActiveAccount(instance.getAllAccounts()[0]);
        }
    }
}
