import { ACTION_TYPES } from "./types/action-types";
import { MUTATION_TYPES } from "./types/mutation-types";
import { createStore } from "vuex";
import { ddpViewerService } from "@/services/DdpViewerService";
import { topicTreeUpdaterService } from "@/services/TopicTreeUpdaterService";
import User from "@/store/modules/user";
import * as UomConsts from "@/common/types/UomConsts";
import router from "@/router";
class State {
    content = null;
    bannerImageUrl = null;
    navigation = null;
    metadata = null;
    uom = {
        Flow: UomConsts.FLOWCONST.LPS.value,
        Force: UomConsts.FORCECONST.N.value,
        Length: UomConsts.LENGTHCONST.MM.value,
        Mass: UomConsts.MASSCONST.KG.value,
        Power: UomConsts.POWERCONST.KW.value,
        Pressure: UomConsts.PRESSURECONST.BAR.value,
        Volume: UomConsts.VOLUMECONST.L.value,
        Temperature: UomConsts.TEMPERATURECONST.C.value,
    };
    loadingNav = false;
    loadingCont = false;
    viewerGuid = "";
    lastUrl = "";
    loadingLang = false;
    selectedNode = null;
}
const mutations = {
    [MUTATION_TYPES.SET_BANNER](state, bannerUrl) {
        state.bannerImageUrl = bannerUrl;
    },
    [MUTATION_TYPES.SET_CONTENT](state, payload) {
        state.content = payload.content;
        if (state.selectedNode) {
            state.selectedNode.selected = false;
        }
        if (payload.selectedNode) {
            payload.selectedNode.selected = true;
            state.selectedNode = payload.selectedNode;
        }
        else {
            state.selectedNode = null;
        }
    },
    [MUTATION_TYPES.SET_NAVIGATION](state, payload) {
        if (!payload.topicId) {
            state.navigation = payload.navigation;
            return;
        }
        topicTreeUpdaterService.update(state.navigation, payload);
    },
    [MUTATION_TYPES.SET_METADATA](state, metadata) {
        state.metadata = metadata;
    },
    [MUTATION_TYPES.SET_UOM](state, uom) {
        state.uom = uom;
    },
    [MUTATION_TYPES.SET_LOADING_NAV](state, isLoading) {
        state.loadingNav = isLoading;
    },
    [MUTATION_TYPES.SET_LOADING_CONT](state, isLoading) {
        state.loadingCont = isLoading;
    },
    [MUTATION_TYPES.SET_VIEWER_GUID](state, guid) {
        state.viewerGuid = guid;
    },
    [MUTATION_TYPES.SET_LAST_URL](state, url) {
        state.lastUrl = url;
    },
    [MUTATION_TYPES.SET_LOADING_LANG](state, loadingLang) {
        state.loadingLang = loadingLang;
    }
};
const actions = {
    async [ACTION_TYPES.GET_BANNER]() {
        const result = await ddpViewerService.getBannerUrl();
        if (result.isSuccess) {
            this.commit(MUTATION_TYPES.SET_BANNER, result.result);
        }
        else {
            console.error(result.errorModel);
        }
    },
    async [ACTION_TYPES.GET_CONTENT](state, payload) {
        this.commit(MUTATION_TYPES.SET_CONTENT, "");
        this.commit(MUTATION_TYPES.SET_LOADING_CONT, true);
        this.commit(MUTATION_TYPES.SET_LAST_URL, payload.url);
        const result = await ddpViewerService.getContent(payload.viewerGuid, payload.url);
        if (result.isSuccess) {
            if (result.result) {
                const template = document.createElement("template");
                template.innerHTML = result.result;
                const images = template.content.querySelectorAll("img");
                for (let i = 0; i < images.length; i++) {
                    if (images[i].src.includes("http")) {
                        // some browsers i.e firefox will append src urls incorrectly when obtaining data from environment variables. 
                        // when this happens correct values must be stored in a variable and applied without invoking the .env 
                        const imageUrl = images[i].src.replace(/(https?:\/\/[^/]*)/g, "");
                        const baseUrl = process.env.VUE_APP_DDP_VIEWER_API_URL;
                        images[i].src = baseUrl + imageUrl;
                    }
                    else {
                        // browsers like chrome or edge need to have the base api urls from the environment prepended to the image url. this is possibly due to Vue2 going out of support
                        images[i].src = process.env.VUE_APP_DDP_VIEWER_API_URL + images[i].src;
                    }
                }
                this.commit(MUTATION_TYPES.SET_CONTENT, {
                    content: template.innerHTML,
                    selectedNode: payload.selectedNode
                });
            }
        }
        else {
            console.error(result.errorModel);
            this.commit(MUTATION_TYPES.SET_CONTENT, "service unavailable");
        }
        this.commit(MUTATION_TYPES.SET_LOADING_CONT, false);
    },
    async [ACTION_TYPES.GET_NAVIGATION](state, payload) {
        const result = await ddpViewerService.getNavigation(payload.viewerGuid, payload.topicId);
        if (result.isSuccess) {
            result.result?.forEach((x) => (x.key = x.id));
            this.commit(MUTATION_TYPES.SET_NAVIGATION, {
                topicId: payload.topicId,
                navigation: result.result,
            });
        }
        else {
            console.error(result.errorModel);
            this.commit(MUTATION_TYPES.SET_NAVIGATION, "service unavailable");
        }
    },
    async [ACTION_TYPES.GET_METADATA](state, payload) {
        const result = await ddpViewerService.getMetadata(payload.viewerGuid);
        this.commit(MUTATION_TYPES.SET_METADATA, result.result);
    },
    async [ACTION_TYPES.POST_MAILREQUEST](state, payload) {
        return ddpViewerService.postMailRequest(payload.email, payload.viewerGuid);
    },
    [ACTION_TYPES.SET_LOADING_NAV](state, isLoading) {
        this.commit(MUTATION_TYPES.SET_LOADING_NAV, isLoading);
        this.commit(MUTATION_TYPES.SET_LOADING_LANG, false);
    },
    [ACTION_TYPES.SET_LOADING_CONT](state, isLoading) {
        this.commit(MUTATION_TYPES.SET_LOADING_CONT, isLoading);
        this.commit(MUTATION_TYPES.SET_LOADING_LANG, false);
    },
    async [ACTION_TYPES.CHANGE_LANGUAGE](state, payload) {
        this.commit(MUTATION_TYPES.SET_LOADING_LANG, true);
        const result = await ddpViewerService.changeLanguage(payload.viewerGuid, payload.language);
        if (!result.isSuccess) {
            ACTION_TYPES;
            this.commit(MUTATION_TYPES.SET_LOADING_LANG, false);
            return "Language not available";
        }
        else {
            const splittedUrl = result.result.split("/");
            const guid = splittedUrl[splittedUrl.length - 1];
            router.push({ name: "home", params: { guid } });
            this.commit(MUTATION_TYPES.SET_LOADING_LANG, false);
            return null;
        }
    },
    [ACTION_TYPES.SET_VIEWER_GUID](state, guid) {
        this.commit(MUTATION_TYPES.SET_VIEWER_GUID, guid);
    },
};
const store = createStore({
    state: new State(),
    mutations: mutations,
    actions: actions,
    modules: { User }
});
export default store;
